import { Flex } from "@chakra-ui/layout";
import React from "react";

interface PageShellWithThreeColumnsProps {
    centralContent: JSX.Element;
    rightContent?: JSX.Element;
    isOpen?: boolean;
}

export const PageShellWithThreeColumns = (props: PageShellWithThreeColumnsProps) => {
    const { centralContent, rightContent, isOpen } = props;

    return (
        <>
            <Flex
                display={{ base: isOpen ? "none" : "block", md: "block" }}
                p={{ base: "0 3% 2% 3%", sm: "0 1%" }}
                minW="40%"
                borderRightWidth={{ lg: "1px", sm: "none" }}
                flex="1"
                flexDirection="column"
                overflow="auto"
                align="stretch"
                h="full"
            >
                {centralContent}
            </Flex>

            <Flex
                display={{ base: isOpen ? "block" : "none", md: "block" }}
                p={{ base: "0 3% 2% 3%", sm: "0 1%" }}
                minW="40%"
                flex="1"
                width={{ base: "100%", lg: "40%" }}
                flexDirection="column"
                overflow="auto"
                align="stretch"
                h="full"
            >
                {rightContent}
            </Flex>
        </>
    );
};