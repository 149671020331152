import React from "react";
import { navigate } from "gatsby";

import { $bool } from "foundation-ts/tsparser";

import { config } from "../config/config";
import { isLoggedIn } from "../services/authentication.service";

const PrivateRoute = ({ path: string, component: Component, ...rest }) => {
    if (!isLoggedIn()) {
        if ($bool(rest.checkParams)) {
            localStorage.setItem("pathname", location.pathname) ;
            localStorage.setItem("search", location.search) ;
        }
        navigate(rest.adminPage ? config.app.adminLoginURL! : config.app.loginURL!);
        return null;
    }
    return <Component {...rest} />;
};

export default PrivateRoute;