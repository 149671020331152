import { Stack, StyleProps, Text, VStack } from "@chakra-ui/react";
import React, { useEffect, useRef, useState } from "react";
import Chart from 'chart.js/auto';

import { $ok } from "foundation-ts/commons";
import { Nullable } from "foundation-ts/types";
import { ConsumptionsStructure } from "g1-commons/lib/doxecureTypes";

import { G1Button } from "../G1Button";
import { SeaShellBox } from "../SeaShellBox";

export interface ConsumptionsBoxProperties extends StyleProps {
    nbQualified: number ;
    nbAdvanced: number ;
    consumptions: Nullable<ConsumptionsStructure> ;
    onExport: () => void ;
}

export const ConsumptionsBox = ({nbQualified, nbAdvanced, consumptions, onExport}: ConsumptionsBoxProperties) => {
    return  (
        <SeaShellBox component="form" spacing="10">
            <Stack width="100%">
                <Text width="100%" fontWeight="bold">Consommation du mois en cours</Text>
                <Text fontSize="medium">Nombre de signatures qualifiées : {nbQualified}</Text>
                <Text fontSize="medium">Nombre de signatures avancées : {nbAdvanced}</Text>
            </Stack>

            <VStack width="100%">
                <Text width="100%" fontWeight="bold">Consommation des 12 derniers mois</Text>
                <ColumnChart data={consumptions} />
                <G1Button component="global" variant="normal" title="Exporter" marginRight="2%" fontSize="md" onClick={ onExport } />
            </VStack>
        </SeaShellBox>
    );
}

interface ColumnChartProperties {
    data: Nullable<ConsumptionsStructure> ;
}

const ColumnChart = ({ data }: ColumnChartProperties) => {
    const chartRef = useRef<HTMLCanvasElement|null>(null) ;
    const [chart, setChart] = useState<Chart|null>(null) ;
  
    useEffect(() => {
        if ($ok(data) && chartRef && chartRef.current) {
            if ($ok(chart)) { chart!.destroy() ; }
            let labels: string[] = [] ;
            let qualified: number[] = [] ;
            let advanced: number[] = [] ;
            for (const key in data) {
                labels.push(key) ;
                qualified.push(data[key].qualified) ;
                advanced.push(data[key].advanced) ;
            }

            const ctx = chartRef.current.getContext('2d') ;
            setChart(new Chart(ctx!, {
                type: 'bar',
                data: {
                    labels: labels,
                    datasets: [{
                        label: 'Nb signatures qualifiées',
                        data: qualified,
                        backgroundColor: 'rgba(54, 162, 100, 0.2)',
                        borderColor: 'rgba(54, 162, 100, 1)',
                        borderWidth: 1
                    },
                    {
                        label: 'Nb signatures avancées',
                        data: advanced,
                        backgroundColor: 'rgba(54, 162, 235, 0.2)',
                        borderColor: 'rgba(54, 162, 235, 1)',
                        borderWidth: 1
                    }]
                },
                options: {
                    responsive: true,
                    scales: {
                        y: {
                            beginAtZero: true
                        }
                    }
                }
            })) ;
      }
    }, [data]) ;

    return <canvas ref={chartRef} /> ;
};