import { $length, $ok } from "foundation-ts/commons";
import { TSError } from "foundation-ts/tserrors";
import { TSDate } from "foundation-ts/tsdate";
import { Nullable, UUID } from "foundation-ts/types";
import { CorporationDto, CreateCorporationBody, CreateUserBody, UpdateCorporationBody, UserDto } from "g1-commons/lib/doxecureClientTypes";
import { Consumptions, ConsumptionsStructure } from "g1-commons/lib/doxecureTypes";

import apiClient, { assertResponse, g1call } from "./apiClient";
import { bytesToDownloadURL } from "../utils/functions";

export const getCorporations = async (): Promise<CorporationDto[]|null> => {
    return await apiClient.getCorporations().then(([ret, status]) => assertResponse(ret, status)) ;
}

export const getCorporation = async (id: UUID): Promise<CorporationDto|null> => {
    return await apiClient.getCorporation(id).then(([ret, status]) => assertResponse(ret, status)) ;
}

export const createCorporation = async (body: CreateCorporationBody): Promise<CorporationDto> => {
    return await apiClient.createCorporation(body).then(([ret, status]) => assertResponse(ret, status)) ;
}

export const updateCorporation = async (id: UUID, body: UpdateCorporationBody): Promise<CorporationDto> => {
    return await apiClient.updateCorporation(id, body).then(([ret, status]) => assertResponse(ret, status)) ;
}

export const deactivateCorporation = async (id: UUID): Promise<boolean> => {
    return await apiClient.deactivateCorporation(id) ;
}

export const reactivateCorporation = async (id: UUID): Promise<boolean> => {
    return await apiClient.reactivateCorporation(id) ;
}

export const getCorporationUsers = async (id: UUID): Promise<UserDto[]|null> => {
    return await apiClient.getCorporationUsers(id).then(([ret, status]) => assertResponse(ret, status)) ;
}

export const createCorporationUser = async (id: UUID, body: CreateUserBody): Promise<UserDto> => {
    return await apiClient.createCorporationUser(id, body).then(([ret, status]) => assertResponse(ret, status)) ;
}

export const getCorporationCurrentConsumption = async (id: UUID): Promise<Nullable<Consumptions>> => {
    const [ret] = await apiClient.corporationCurrentConsumption(id) ;
    return ret ;
}

export const getCorporationConsumptions = async (id: UUID): Promise<Nullable<ConsumptionsStructure>> => {
    const [ret] = await apiClient.corporationConsumptions(id) ;
    return ret ;
}

export const exportCorporationConsumptions = async (id: UUID, corporationName: string): Promise<void> => {
    let url:Nullable<string> = undefined ;
    const ret = await g1call('download csv consumptions', c => c.corporationDownloadConsumptions(id)) ;
    if ($ok(ret)) { const [data] = ret! ; url = bytesToDownloadURL(Buffer.from(data! )) ; }
    if ($length(url) === 0) { 
        TSError.throw("Impossible d'exporter les données") ;
    } else {
        const date = TSDate.fromZulu(new Date()) ;
        const link = document.createElement('a') ;
        link.href = url! ;
        link.download = `consommations_${corporationName}_${date?.toISOString()}.csv`;
        link.click() ;
    }
}

export const requestAddDomains = async (id: UUID): Promise<boolean> => {
    return await apiClient.requestAddDomainsToCorporation(id) ;
}

export const createAdminCorporate = async (id: UUID, body: CreateUserBody): Promise<UserDto> => {
    return await apiClient.createAdminCorporate(id, body).then(([ret, status]) => assertResponse(ret, status)) ;
}

export const linkUserToCorporation = async (corporateId: UUID, userId: UUID): Promise<UserDto> => {
    return await apiClient.linkUserToCorporation(corporateId, userId).then(([ret, status]) => assertResponse(ret, status)) ;
}