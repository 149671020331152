import { HStack, Icon, Link, LinkProps, Text } from "@chakra-ui/react";
import React from "react";
import { navigate } from "gatsby";

import { $g1Color } from "../../@chakra-ui/gatsby-plugin/G1Style";

interface NavLinkProps extends LinkProps {
    component: "sidebar.menu" ;
    to: string ;
    isActive?: boolean ;
    label: string ;
    icon?: React.ElementType ;
}

export const NavLink = (props: NavLinkProps) => {
    const { component, icon, isActive, label, to, ...rest } = props ;

    const handleNavigate = async event => {
        event.preventDefault() ;
        navigate(to) ;
    }

    return (
        <Link
            display="block"
            py="2"
            px="3"
            borderRadius="md"
            transition="all 0.3s"
            fontWeight="medium"
            fontSize={{ base: "12px", sm: "15px" }}
            userSelect="none"
            aria-current={isActive ? "page" : undefined}
            color={$g1Color(`${component}.write`)}
            bg={$g1Color(`${component}.bg`)}
            _hover={{ bg: $g1Color(`${component}.hover.bg`), color: $g1Color(`${component}.hover.write`) }}
            _activeLink={{ bg: $g1Color(`${component}.select.bg`), color: $g1Color(`${component}.select.write`) }}
            onClick={handleNavigate}
            {...rest}
        >
            <HStack spacing="4">
                <Icon as={icon} color="currentColor" />
                <Text as="span">{label}</Text>
            </HStack>
        </Link>
    );
};