import { FormControl, InputGroup, InputGroupProps, InputLeftElement } from "@chakra-ui/react";
import React from "react";

import { $ok } from "foundation-ts/commons";

import { $g1Color } from "../../@chakra-ui/gatsby-plugin/G1Style";

import { G1Icon } from "../../utils/icons";
import { Component } from "../../utils/TypesAndConstants";

import { G1Input } from "../G1Input";

interface InputSearchProps extends InputGroupProps {
    component: Component ;
    search?: string;
    placeHolder?: string;
}

export const SearchField = ({ search, placeHolder, component, onChange, ...rest }: InputSearchProps) => {
    return (
        <FormControl id="search" size="sm" {...rest}>
            <InputGroup size="sm" height="100%">
                <InputLeftElement pointerEvents="none" color={$g1Color(`${component}.search.icon`)}>
                    <G1Icon.Search />
                </InputLeftElement>
                <G1Input component={component} type="search" height="100%" rounded="md" placeholder={$ok(placeHolder) ? placeHolder : ""} paddingInlineStart={8} defaultValue={search} onChange={onChange} />
            </InputGroup>
        </FormControl>
    );
};