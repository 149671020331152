import { Box, useMultiStyleConfig } from "@chakra-ui/react";
import React from "react";

interface ErrorFieldsProps {
    fieldName: string;
    error?: string;
}

export const ErrorField = (props: ErrorFieldsProps) => {
    const { fieldName, error } = props;
    const styles = useMultiStyleConfig("ErrorField", {});
    return (
        <Box as="span" __css={styles}>
            {error || `Le champ ${fieldName} est requis`}
        </Box>
    );
};
