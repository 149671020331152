import { $length, $ok } from "foundation-ts/commons";
import { TSDate } from "foundation-ts/tsdate";
import { TSError } from "foundation-ts/tserrors";
import { Nullable, UUID } from "foundation-ts/types";

import { Consumptions, ConsumptionsStructure, } from "g1-commons/lib/doxecureTypes";

import { bytesToDownloadURL } from "../utils/functions";
import apiClient, { g1call } from "./apiClient";

export const getCurrentConsumption = async (userId?: Nullable<UUID>): Promise<Nullable<Consumptions>> => {
    const [ret] = $ok(userId) ? await apiClient.userCurrentConsumption(userId!) : await apiClient.currentConsumption() ;
    return ret ;
}

export const getConsumptions = async (userId?: Nullable<UUID>): Promise<Nullable<ConsumptionsStructure>> => {
    const [ret] = $ok(userId) ? await apiClient.userConsumptions(userId!) : await apiClient.consumptions() ;
    return ret ;
}

export const exportConsumptions = async (userId?: Nullable<UUID>, username?: Nullable<string>): Promise<void> => {
    let url:Nullable<string> = undefined ;
    const ret = await g1call('download csv consumptions', c => c.downloadConsumptions(userId)) ;
    if ($ok(ret)) { const [data] = ret! ; url = bytesToDownloadURL(Buffer.from(data! )) ; }
    if ($length(url) === 0) { 
        TSError.throw("Impossible d'exporter les données") ;
    } else {
        const date = TSDate.fromZulu(new Date()) ;
        const link = document.createElement('a') ;
        link.href = url! ;
        link.download = "consommations_" + ($ok(username) ? `${username}_` : "") + `${date?.toISOString()}.csv`;
        link.click() ;
    }
}