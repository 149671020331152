import { Box, Divider, Flex, FlexProps, Image, Spacer, Stack, Text } from "@chakra-ui/react";
import { navigate } from "@reach/router";
import React, { FormEvent, useEffect, useState } from "react";
import { Link } from "gatsby";

import { $g1Color, $g1IconURL } from "../../@chakra-ui/gatsby-plugin/G1Style";

import { config } from "../../config/config";
import { logout } from "../../services/authentication.service";
import { getUserUuid } from "../../services";
import { trueOrEmptyCard } from "../../utils/functions";
import { G1Icon } from "../../utils/icons";

import { NavLink } from "../NavLink/NavLink";
import { SearchField } from "../SearchField/SearchField";
import { UserProfile } from "../UserProfile/UserProfile";
import VersionDisplay from "../VersionDisplay";

interface MenuContent {
    primary: MenuItem[];
    secondary: MenuItem[];
}

interface MenuItem {
    key: string;
    label: string;
    icon: React.ElementType;
    route: string;
}

export interface SidebarProps extends FlexProps {
    activeItemKey: string ;
    onSearch: (searchString: string) => void ;
    activesearch?: boolean ;
    adminPage?: boolean ;
    adminCorporate?: boolean ;
}

const emptyMenuContent: MenuContent = { primary: [], secondary: [] } ;

const adminMenuContent: MenuContent = {
    primary: [
        {
            key: "corporations",
            icon: G1Icon.Corporations,
            label: "Organisations",
            route: "/admin/corporations"
        },
        {
            key: "users",
            icon: G1Icon.Contacts,
            label: "Utilisateurs",
            route: "/admin/users"
        },
        {
            key: "consumptions",
            icon: G1Icon.Statistics,
            label: "Consommations",
            route: "/admin/consumptions"
        }
    ],
    secondary: []
} ;

const managerMenuContent: MenuContent = {
    primary: [
        {
            key: "corporations",
            icon: G1Icon.Corporations,
            label: "Organisation",
            route: "/admin/corporations"
        },
        {
            key: "users",
            icon: G1Icon.Contacts,
            label: "Collaborateurs",
            route: "/admin/users"
        },
        {
            key: "consumptions",
            icon: G1Icon.Statistics,
            label: "Consommations",
            route: "/admin/consumptions"
        }
    ],
    secondary: []
} ;

const appMenuContent: MenuContent = {
    primary: [
        {
            key: "signatures",
            icon: G1Icon.Signatures,
            label: "Parapheur",
            route: "/app/signatures",
        },
        {
            key: "newSignature",
            icon: G1Icon.NewSignature,
            label: "Mettre à la signature",
            route: "/app/newSignature",
        },
        {
            key: "draft",
            icon: G1Icon.Draft,
            label: "Brouillons",
            route: "/app/draft",
        },
        {
            key: "toBeSigned",
            icon: G1Icon.ToBeSignedDocuments,
            label: "En attente de signature",
            route: "/app/tobeSignedDocuments",
        },
        {
            key: "signedDocuments",
            icon: G1Icon.SignedDocuments,
            label: "Documents signés",
            route: "/app/signedDocuments",
        },
        {
            key: "refusedDocuments",
            icon: G1Icon.RefusedDocuments,
            label: "Documents refusés",
            route: "/app/refusedDocuments"
        },
        {
            key: "canceledDocuments",
            icon: G1Icon.CanceledDocuments,
            label: "Documents résiliés",
            route: "/app/canceledDocuments"
        },
        {
            key: "expiredDocuments",
            icon: G1Icon.ExpiredDocuments,
            label: "Documents expirés",
            route: "/app/expiredDocuments",
        },
        {
            key: "archivedDocuments",
            icon: G1Icon.ArchivedDocuments,
            label: "Documents archivés",
            route: "/app/archivedDocuments"
        }
    ],
    secondary: [],
};

if (!config?.demo) {
    appMenuContent.secondary = [
        {
            key: "contacts",
            icon: G1Icon.Contacts,
            label: "Contacts",
            route: "/app/contacts",
        },
    ] ;
    if (config.app.templateManagement) {
        appMenuContent.secondary.push({
            key: "templates",
            icon: G1Icon.Templates,
            label: "Modèles",
            route: "/app/templates"
        }) ;
        appMenuContent.secondary.push({
            key: "newTemplate",
            icon: G1Icon.CreateTemplate,
            label: "Créer/Editer un modèle",
            route: "/app/newTemplate"
        }) ;
    }
}

export const Sidebar = ({ ...props }: SidebarProps) => {
    const { activeItemKey, onSearch, activesearch, adminPage, adminCorporate, ...rest } = props;
    const [menuContent, setMenuContent] = useState<MenuContent>(emptyMenuContent) ;
    const profileId = getUserUuid() ;
//    const menuContent = adminPage ? (adminCorporate ? managerMenuContent : adminMenuContent) : appMenuContent ;

    const handleSearch = (e: FormEvent) => {
        e.preventDefault() ;
        if (e.type === "submit") {
            // @ts-ignore
            onSearch(e.target[0].value) ;
        }
    };

    async function _handleClickLogout(e) {
        e.preventDefault() ;
        await logout() ;
        navigate(adminPage ? config.app.adminLoginURL! : config.app.loginURL!) ;
    }

    useEffect(() => {
        setMenuContent(adminPage ? (adminCorporate ? managerMenuContent : adminMenuContent) : appMenuContent) ;
    }, [adminPage, adminCorporate]) ;

    return (
        <Flex direction="column" borderRightWidth="1px" width="64" color={$g1Color("sidebar.write")} bg={$g1Color("sidebar.bg")} {...rest}>
            <Flex direction="column" flex="1" pt="5" pb="4" overflowY="auto" px="4">
                <Box mb="6" display="flex" justifyContent="space-between" alignItems="flex-end" color={$g1Color("sidebar.logo.write")} bg={$g1Color("sidebar.logo.bg")}>
                    <Stack direction="row" alignItems="center" spacing="5">
                        <Image src={$g1IconURL("sidebar.logo.iconurl")} alt="Application logo" boxSize="48px" />
                        <Text>{adminPage ? "Administration de " : ""}{config.app.title}</Text>
                    </Stack>
                </Box>
                {!adminPage && !activesearch && (
                    <Box mb="6">
                        <form onSubmit={handleSearch}>
                            <SearchField component="sidebar" h="30px" />
                        </form>
                    </Box>
                )}

                <Stack spacing="6" as="nav" aria-label="Sidebar Navigation">
                    <Stack spacing="1">
                        {menuContent.primary.map((menuItem) => {
                            return (
                                <NavLink
                                    component="sidebar.menu"
                                    key={menuItem.key}
                                    label={menuItem.label}
                                    icon={menuItem.icon}
                                    to={menuItem.route}
                                    isActive={menuItem.key === activeItemKey}
                                />
                            );
                        })}
                    </Stack>
                    {trueOrEmptyCard(menuContent.secondary.length > 0,
                        <>
                            <Divider />

                            <Stack spacing="1">
                                {menuContent.secondary.map((menuItem) => {
                                    return (
                                        <NavLink
                                            component="sidebar.menu"
                                            key={menuItem.key}
                                            label={menuItem.label}
                                            icon={menuItem.icon}
                                            to={menuItem.route}
                                            isActive={menuItem.key === activeItemKey}
                                        />
                                    );
                                })}
                            </Stack>
                        </>)
                    }
                </Stack>
                <Spacer />
            </Flex>

            <Box position="relative">
                <VersionDisplay />
                {adminPage ? 
                    <UserProfile profileId={profileId} adminPage={true} logout={_handleClickLogout} />
                  : <Link to="/app/userProfile/account"><UserProfile profileId={profileId} logout={_handleClickLogout}/></Link>
                }    
            </Box>
        </Flex>
    );
};
