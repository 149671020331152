import {
    Drawer,
    DrawerCloseButton,
    DrawerContent,
    DrawerOverlay,
    Flex,
    IconButton,
} from "@chakra-ui/react";
import React from "react";

import { $g1Color } from "../../@chakra-ui/gatsby-plugin/G1Style";

import { config } from "../../config/config";
import { G1Icon } from "../../utils/icons";

import { Sidebar } from "../Sidebar/Sidebar";
import { SidebarProps } from "../Sidebar/Sidebar";

import { useMobileMenuState } from "./useMobileMenuState";

export interface MobileTopBarProps extends SidebarProps {}

export const MobileTopBar = (props: MobileTopBarProps) => {
    const { activeItemKey, onSearch, adminPage, adminCorporate } = props ;
    const { isOpen, onClose, onOpen } = useMobileMenuState() ;

    return (
        <Flex
            align="center"
            justify="start"
            py="2"
            px="4"
            bg={$g1Color('topbar.bg')}
            color={$g1Color('topbar.write')}
            display={{ base: "flex", md: "none" }}
            borderBottomWidth="1px"
        >
            <IconButton
                onClick={onOpen}
                variant="unstyled"
                display="flex"
                cursor="pointer"
                aria-label="Menu"
                mr="2%"
                icon={<G1Icon.MenuBurger fontSize="1.5rem" />}
                color="currentColor"
            />
            {config.app.title}
            <Drawer size="md" placement="left" isOpen={isOpen} blockScrollOnMount={false} onClose={onClose}>
                <DrawerOverlay />
                <DrawerContent shadow="none" position="relative" maxW="80%">
                    <DrawerCloseButton position="absolute" />
                    <Sidebar
                        width="full"
                        height="full"
                        border="0"
                        activeItemKey={activeItemKey}
                        onSearch={onSearch}
                        adminPage={adminPage}
                        adminCorporate={adminCorporate}
                    />
                </DrawerContent>
            </Drawer>
        </Flex>
    ) ;
} ;