import { Avatar, Flex, FlexProps, HStack, Text } from "@chakra-ui/react";
import React, { MouseEventHandler } from "react";

import { Nullable, UUID } from "foundation-ts/types";
import { $length, $ok } from "foundation-ts/commons";

import { $g1Color } from "../../@chakra-ui/gatsby-plugin/G1Style";

import useProfile from "../../hooks/useProfile";
import { adminTitle, optlog, trueOrEmptyCard } from "../../utils/functions";
import { G1Icon } from "../../utils/icons";

export interface UserProfileProps extends FlexProps {
    profileId: Nullable<UUID> ;
    logout: MouseEventHandler<SVGElement> ;
    adminPage?: boolean ;
}

export const UserProfile = (props: UserProfileProps) => {
    const { profileId, adminPage, logout } = props ;
    const { data: userProfile, isError: profileError, isLoading: profileLoading } = useProfile(profileId, 'UserProfile') ;

    function _v(v: Nullable<string>, sepa?:Nullable<string>): string {
        return profileError || !$length(v) ? '' : (profileLoading ? 'en chargement...' : v! + ($length(sepa)?sepa!:'')) ;
    }

    optlog(`UserID: '${profileId}' loading: ${profileLoading} error: ${profileError}, profile: ${$ok(userProfile)?'OK':'<UNDEFINED>'}`) ;
    return trueOrEmptyCard(
        !profileError,
        <HStack
            bg={$g1Color('sidebar.profile.bg')}
            color={$g1Color('sidebar.profile.write')}
            spacing="4"
            px="2"
            flexShrink={0}
            borderTopWidth="1px"
            p="4"
            cursor="pointer"
        >
            <Avatar size="sm" name={_v(userProfile?.lastName)} src={_v(userProfile?.avatarUrl)} color={$g1Color('sidebar.profile.avatar.write')} bg={$g1Color('sidebar.profile.avatar.bg')} />
            <Flex direction="column" fontWeight="medium" width="100%">
                <Text fontSize="15px">{_v(userProfile?.firstName?.capitalize(), ' ')}{_v(userProfile?.lastName?.toUpperCase())}</Text>
                <Text fontSize="12px" lineHeight="shorter" wordBreak="break-all">
                    {adminPage ? adminTitle(userProfile?.userFlags?.profile) : _v(userProfile?.email?.trim())}
                </Text>
            </Flex>
            <Flex>
                <G1Icon.Logout size="25px" onClick={logout} style={{ marginLeft: 'auto' }} color={$g1Color('sidebar.profile.icon')} />
            </Flex>
        </HStack>,
        false,
        profileError,
        "Impossible de récupérer le profil de l'utilisateur",
        "Connexion invalide",
    ) ;
} ;