import { Box } from "@chakra-ui/layout";
import { StyleProps } from "@chakra-ui/styled-system";

import React from "react";

import { $count } from "foundation-ts/commons";

import { trueOrEmptyCard } from "../../utils/functions";

interface SectionBoxProps<T, P> {
    items?: Array<T>;
    parent?: P;
    sectionStyle: StyleProps;
    renderItem: (item: T, parent?: P, index?: number) => any;
    renderHeader?: () => void;
    renderFooter?: () => void;
}

export const SectionBox = <T, P>(props: SectionBoxProps<T, P>) => {
    const { parent, items, renderHeader, renderFooter, renderItem, sectionStyle } = props;
    const { color, rounded, overflow, width, bg, fontWeight, marginBottom } = sectionStyle;

    return (
        <React.Fragment>
            <Box
                color={color}
                bg={bg}
                rounded={rounded}
                overflow={overflow}
                width={width}
                fontWeight={fontWeight}
                marginBottom={marginBottom}
            >
                <>
                {trueOrEmptyCard($count(items) > 0, (
                    <>
                        {/* header */}
                        {renderHeader && renderHeader()}

                        {/* List des items */}
                        {items!.map((item: T, index: number) => renderItem(item, parent, index))}
                    </>
                ))}
                {/* footer */}
                {renderFooter && renderFooter()}
                </>
            </Box>
        </React.Fragment>
    );
};
