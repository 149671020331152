import { Box, Flex, useToast } from "@chakra-ui/react";
import { navigate } from "@reach/router";
import React, { Children, cloneElement, isValidElement, useEffect, useState } from "react";
import { PageProps } from "gatsby";

import { $ok } from "foundation-ts/commons";
import { Nullable } from "foundation-ts/types";
import { UserProfile } from "g1-commons/lib/doxecureTypes";
import { UserDto } from "g1-commons/lib/doxecureClientTypes";

import { $g1Color } from "../../@chakra-ui/gatsby-plugin/G1Style";

import { config } from "../../config/config";
import { useColorContext } from "../../hooks/useColorContext";
import { MobileTopBar } from "../MobileTopBar/MobileTopBar";
import { Sidebar } from "../Sidebar/Sidebar";
import { getAdminProfile, getUserProfile } from "../../services/users.service";
import { getUserUuid } from "../../services";
import { defaultErrorToast } from "../../utils/toast";
import { forceLogout } from "../../utils/functions";

type OwnProps = {
    children: React.ReactNode ;
};

export type AuhenticatedLayoutProps = PageProps & OwnProps ;

export const AuthenticatedLayout = ({ children, location: { pathname } }: AuhenticatedLayoutProps) => {
    const [connectedUserProfile, setConnectedUserProfile] = useState<Nullable<UserDto>>(null) ;
    const toast = useToast() ;
    // The path is like "/app/xxx" where xxx corresponds to the menu item key
    const activeMenuItem = pathname.split("/")[2] ; // We remove the leading slash in pathname to get the active menu item key
    const adminPage = pathname.split("/")[1] === "admin" ;
    const isSearchPage = pathname === "/app/search" ;

    const enhancedChildren = Children.map(children, child => {
        if (isValidElement(child)) {
            return cloneElement(child, { connectedUserProfile }) ;
        }
        return child ;
    }) ;

    const handleSearch = (searchString: string) => {
        navigate("/app/search", { state: { search: searchString } }) ;
    } ;

    useEffect(() => {
        const checkUserProfile = async () => {
            try {
                const uid = getUserUuid() ;
                const user = adminPage ? await getAdminProfile(uid!) : await getUserProfile(uid!) ;
                setConnectedUserProfile(user) ;
            } catch (e) {
                toast(defaultErrorToast("Vous n'êtes pas autorisé à accéder à cette page")) ;
                forceLogout(config.app.adminLoginURL!) ;
            }
        }
        
        if (!$ok(connectedUserProfile)) {
            checkUserProfile() ;
        }
    }, [connectedUserProfile]) ;

    useColorContext() ;
    return (
        <Box as="section" bg={$g1Color('global.bg')} px="0" py="0">
            <Box px={{ base: "0" }}>
                <Flex h="100vh" flexDirection="column">
                    <MobileTopBar activeItemKey={activeMenuItem} onSearch={handleSearch} adminPage={adminPage} adminCorporate={connectedUserProfile?.userFlags?.profile === UserProfile.AdministratorCorporate} />
                    <Flex flex="1" overflow="hidden">
                        <Sidebar
                            minW="20%"
                            display={{ base: "none", md: "flex" }}
                            activeItemKey={activeMenuItem}
                            onSearch={handleSearch}
                            activesearch={isSearchPage}
                            adminPage={adminPage}
                            adminCorporate={connectedUserProfile?.userFlags?.profile === UserProfile.AdministratorCorporate}
                        />
                        {enhancedChildren}
                    </Flex>
                </Flex>
            </Box>
        </Box>
    ) ;
} ;