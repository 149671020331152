import { Box, Flex } from "@chakra-ui/layout";
import { ChakraProps } from "@chakra-ui/react";
import React from "react";

import { $ok } from "foundation-ts/commons";
import { UserBodyCommon, UserFlags } from "g1-commons/lib/doxecureTypes";
import { userName } from "g1-commons/lib/doxecureFunctions";

import { G1Icon } from "../../utils/icons";
import { CustomItemType } from "../../utils/TypesAndConstants";

import { CardDescription } from "./CardDescription";
import { userRoleAndStatus } from "../../utils/functions";

export interface UserCardItem extends UserBodyCommon {
    avatarUrl?: string ;
    organizationName?: string ;
    userFlags?: UserFlags ;
}
interface UserCardProps extends ChakraProps {
    item: UserCardItem ;
    itemStyle: CustomItemType ;
    displayIcon?: boolean ;
    displayOrganizationName?: boolean ;
    displayRole?: boolean ;
    locked?: boolean ;
    isAdminCorporate?: boolean ,
    upDownButtons?: any ;
    onItemClick?: () => void ;
    onRemoveItemClick?: () => void ;
}

export const UserCard = (props: UserCardProps) => {
    const {
        itemStyle,
        item,
        displayIcon = false,
        displayOrganizationName = false,
        displayRole = false,
        locked,
        isAdminCorporate,
        upDownButtons,
        onRemoveItemClick,
        onItemClick,
        ...rest
    } = props ;
    return (
        <Flex
            p="2"
            width="100%"
            minH="6em"
            borderTop={itemStyle.borderTop ?? "1px"}
            borderTopColor={itemStyle.borderTopColor ?? "inherit"}
            _hover={$ok(onItemClick) || $ok(onRemoveItemClick) ? itemStyle.hover : undefined}
            _focus={itemStyle.select}
            cursor={$ok(onItemClick) || $ok(onRemoveItemClick) ? "pointer" : "default"}
            onClick={onItemClick}
            {...rest}
        >
            <CardDescription width="100%" locked={locked} icon={item.avatarUrl} title={`${userName(item)}`} moreInfos={`${item.email}`} organizationName={`${(displayOrganizationName && item.organizationName) || ""}`} role={`${displayRole ? userRoleAndStatus(item.userFlags, isAdminCorporate) : ""}`} />
            {displayIcon && (
                <Box alignSelf="center" width="20%" display="flex" justifyContent="flex-end">
                    {upDownButtons}
                    <Box margin="5%" alignSelf="center" color={itemStyle.iconColor ?? "inherit"}>
                        { $ok(onRemoveItemClick) ? <G1Icon.Minus size="20px" cursor="pointer" onClick={onRemoveItemClick} /> : <G1Icon.View size="20px" cursor="pointer" /> }
                    </Box>
                </Box>
            )}
        </Flex>
    ) ;
} ;