import { useQuery } from "react-query";

import { $ok } from "foundation-ts/commons";
import { TSCountry } from "foundation-ts/tscountry";
import { Languages } from "foundation-ts/types";
import { G1Country } from "g1-commons/lib/doxecureLocales";

import apiClient from "../services/apiClient";

import { CountriesID } from "./hooksConstants";

const useCountries = () =>  useQuery([CountriesID], async () => {
    const countries: G1Country[] = [] ;
    const [countriesList, _status] = await apiClient.getAvailableCountries() ;
    if ($ok(countriesList)) { 
        countriesList?.forEach(country => {
            const myCountry = TSCountry.country(country) ;
            if ($ok(myCountry)) {
                const name = myCountry?.translatedName(Languages.fr) ;
                if ($ok(name)) { countries.push({ code: country, name: name! }) ; }
            }
        }) ;
    }
    return countries ;
}) ;

export default useCountries;