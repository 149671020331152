import { Avatar, Box, Stack, StyleProps, Text, Center } from "@chakra-ui/react";
import React from "react";

import { $ok } from "foundation-ts/commons";

import { G1Icon } from "../../utils/icons";

interface CardDescriptionProps extends StyleProps {
    icon?: string|JSX.Element ;
    title: string ;
    description?: string ;
    moreInfos?: string ;
    organizationName?: string ;
    role?: string ;
    locked?: boolean ;
}

export const CardDescription = (props: CardDescriptionProps) => {
    const { title, icon, description, moreInfos, organizationName, role, locked } = props;
    return (
        <Stack
            overflow="hidden"
            width={props.width}
            direction={{ base: "row" }}
            spacing="5"
            justify="space-between"
            pos="relative"
            p="2%"
        >
            <Stack direction={{ base: "row" }} spacing="4" align="flex-start" flex="1" alignItems="center">
                {typeof icon === "string" ? (
                    <Box aria-hidden pt="1" color="gray.500">
                        <Avatar w={{ base: "30px", sm: "50px" }} h={{ base: "30px", sm: "50px" }} src={icon} />
                    </Box>
                ) : (
                    <Box aria-hidden pt="1" color="white">
                        {icon}
                    </Box>
                )}

                <Box flex="1">
                    <Stack direction="row" spacing={4} align="left">
                        <Box textAlign="left" as="h4" fontWeight="bold">
                            <span>{title}</span>
                        </Box>
                        {!!locked && (<Center>
                                <Text fontSize={"14px"}>(</Text>
                                <G1Icon.Lock size="12px" />
                                <Text fontSize={"14px"}>&nbsp;signature qualifiée)</Text>
                            </Center>)}
                    </Stack>
                    {$ok(organizationName) &&
                        <Text width="100%" textAlign="left" fontStyle="italic" fontWeight="100">
                            {organizationName}
                        </Text>
                    }
                    {description && (
                        <Box width="100%" textAlign="left" as="h4" fontWeight="100">
                            {description}
                        </Box>
                    )}
                    {moreInfos && (
                        <Text width="100%" textAlign="left" as="h4" fontWeight="100">
                            {moreInfos}
                        </Text>
                    )}
                    {role && (
                        <Text width="100%" textAlign="left" fontWeight="100">
                            {role}
                        </Text>
                    )}
                </Box>
            </Stack>
        </Stack>
    ) ;
} ;