import { Flex, FormControl, FormLabel, HStack, Select, VStack } from "@chakra-ui/react";
import { FieldErrors, FieldValues, UseFormRegister } from "react-hook-form";
import React from "react";

import { $ok } from "foundation-ts/commons";
import { G1Country } from "g1-commons/lib/doxecureLocales";

import { $g1Color } from "../../@chakra-ui/gatsby-plugin/G1Style";

import { ErrorField } from "../ErrorField";
import { G1Input } from "../G1Input";

type AddressFormProperties<T extends FieldValues> = {
    required: boolean ;
    disabled?: boolean ;
    countries?: G1Country[] ;
    errors: FieldErrors<T> ;
    prepId?: string ;
    firstPosition?: boolean ;
    displayCountry?: boolean ;
    register: UseFormRegister<T> ;
    onChange: (e) => void ;
};

export type AddressFormValues = {
    streetNumber?: string,
    street?: string,
    streetComplement?: string,
    zipCode?: string,
    city: string,
    country: string
} ;

export const AddressForm = (props: AddressFormProperties<FieldValues>) => {
    const { prepId, required, disabled, firstPosition, displayCountry = true, countries, errors, register, onChange } = props ;
    const nameStreetNumber = `${$ok(prepId) ? prepId : ""}streetNumber` ;
    const nameStreet = `${$ok(prepId) ? prepId : ""}street` ;
    const nameStreetComplement = `${$ok(prepId) ? prepId : ""}streetComplement` ;
    const nameZipCode = `${$ok(prepId) ? prepId : ""}zipCode` ;
    const nameCity = `${$ok(prepId) ? prepId : ""}city` ;
    const nameCountry = `${$ok(prepId) ? prepId : ""}country` ;

    return (
        <FormControl isRequired={required} isDisabled={disabled} marginTop={firstPosition ? 0 : 3}>
            <FormLabel fontSize="1.25em">Adresse</FormLabel>
            <VStack width="100%" spacing="3" align="stretch">
                <HStack>
                    <G1Input component="form" id={nameStreetNumber} registerInput={{ ...register(nameStreetNumber) }} width="30%" placeholder="No" onChange={onChange} />
                    <G1Input component="form" id={nameStreet} registerInput={{ ...register(nameStreet) }} placeholder="Rue" onChange={onChange} />
                </HStack>

                <G1Input component="form" id={nameStreetComplement} registerInput={{ ...register(nameStreetComplement) }} marginBottom="2%" width="100%" placeholder="Complément d'adresse" onChange={onChange} />
                <HStack>
                    <G1Input component="form" id={nameZipCode} registerInput={{ ...register(nameZipCode) }} placeholder="Code postal" width="40%" maxLength={6} onChange={onChange} />
                    <Flex direction="column" width="100%">
                        <G1Input component="form" id={nameCity} registerInput={{ ...register(nameCity, { required: required }) }} placeholder="Ville" textTransform="uppercase" isRequired onChange={onChange} />
                        {errors[nameCity] && <ErrorField fieldName="Ville" />}
                    </Flex>
                </HStack>
                {displayCountry &&
                    <Select id={nameCountry} {...register(nameCountry)} bg={$g1Color('form.field.bg')} borderColor={$g1Color('form.field.border')} isRequired autoComplete="true" defaultValue={countries?.first()?.code} onChange={onChange}>
                        {countries?.map((country) => (
                            <option value={country.code} key={country.code}>
                                {country.name}
                            </option>
                        ))}
                    </Select>
                }
            </VStack>
        </FormControl>
    ) ;
} ;