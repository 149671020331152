import { FormControl, FormLabel, HStack, Select, Text } from "@chakra-ui/react";
import { FieldErrors, FieldValues, UseFormRegister } from "react-hook-form";
import React, { ChangeEvent, useState } from "react";

import { $isfunction, $keys, $ok } from "foundation-ts/commons";
import { Nullable } from "foundation-ts/types";
import { G1Country } from "g1-commons/lib/doxecureLocales";
import { CorporationIdentifiersDictionary, CorporationIdentifierType } from "g1-commons/lib/doxecureTypes";

import { $g1Color } from "../../@chakra-ui/gatsby-plugin/G1Style";

import { AddressForm, AddressFormValues } from "../AddressForm/AddressForm";
import { ErrorField } from "../ErrorField";
import { G1Input } from "../G1Input";

type CorporationFormProperties<T extends FieldValues> = {
    label: string ;
    required: boolean ;
    disabled?: boolean ;
    countries?: G1Country[] ;
    corporationIdentifiersDict?: CorporationIdentifiersDictionary ;
    selectedCIT?: CorporationIdentifierType ;
    prepId?: string ;
    showUniqueId?: boolean ;
    errors: FieldErrors<T>;
    register: UseFormRegister<T>;
    onChange: (e) => void ;
    onCountryChange?: (e: ChangeEvent<HTMLSelectElement>) => void ;
    validate?: (id: string) => boolean ;
} ;

export type CorporationFormValues = {
    organizationName: string,
    organizationId: string,
    vatNumber: string
} & AddressFormValues ;

export const CorporationForm = (props: CorporationFormProperties<FieldValues>) => {
    const { label, prepId, required, disabled, showUniqueId, countries, corporationIdentifiersDict, errors, register, onChange, onCountryChange, validate } = props ;
    const [corporationIdDescription, setCorporationIdDescription] = useState<Nullable<string>>(null) ;
    const [selectedCorporationIdentifierType, setSelectedCorporationIdentifierType] = useState<CorporationIdentifierType|null>(null) ;

    const handleCountryChange = (e: ChangeEvent<HTMLSelectElement>) => {
        if ($isfunction(onCountryChange)) { onCountryChange!(e) ; }
        setCorporationIdDescription("") ;
    }

    const handleOrganizationIdTypeChange = (e: ChangeEvent<HTMLSelectElement>) => {
        setSelectedCorporationIdentifierType(e.currentTarget.value as CorporationIdentifierType) ;
        setCorporationIdDescription(corporationIdentifiersDict![e.currentTarget.value].desc) ;
        onChange(e) ;
    }

    return (
        <>
            <FormControl isRequired={required} isDisabled={disabled}>
                <FormLabel fontSize="1.25em">{label}</FormLabel>
                <G1Input component="form" registerInput={{ ...register("organizationName", { required: required }) }} textTransform="uppercase" onChange={onChange} />
                { errors.organizationName && <ErrorField fieldName={label} /> }
            </FormControl>

            { showUniqueId ? 
                <>
                    <FormControl isRequired={required} isDisabled={disabled} marginTop={3}>
                        <FormLabel fontSize="1.25em">Pays de résidence</FormLabel>
                        <Select {...register("organizationCountry")} bg={$g1Color('form.field.bg')} borderColor={$g1Color('form.field.border')} isRequired={required} autoComplete="true" onChange={handleCountryChange}>
                            {countries?.map((country) => (
                                <option value={country.code} key={country.code}>
                                    {country.name}
                                </option>
                            ))}
                        </Select>
                    </FormControl>

                    <FormControl isRequired={required} isDisabled={disabled} marginTop={3}>
                        <HStack display="flex" >
                            <FormLabel fontSize="1.25em">Identifiant de type</FormLabel>
                            <Select {...register("organizationIdentifierType")} bg={$g1Color('form.field.bg')} borderColor={$g1Color('form.field.border')} isRequired={required} autoComplete="true" width="fit-content" onChange={handleOrganizationIdTypeChange}>
                                {$keys(corporationIdentifiersDict)?.map((identifier) => (
                                    <option value={identifier} key={identifier}>
                                        {corporationIdentifiersDict![identifier]?.name}
                                    </option>
                                ))}
                            </Select>
                        </HStack>
                        { errors.organizationIdType && <ErrorField fieldName="Type d'identifiant" /> }
                        <G1Input component="form" registerInput={{...register("organizationIdentifier", { required: required, validate: (value: string) => $isfunction(validate) && validate!(value) })}} placeholder="Identifiant sans espace, ni tiret, ni point" onChange={onChange} />
                        { $ok(corporationIdDescription) && <Text fontSize="smaller" fontStyle="italic" marginTop="0.2em">{corporationIdDescription}</Text> }
                        { errors.organizationIdentifier && <ErrorField fieldName="Identifiant de l'organisation" error={`L'identifiant "${selectedCorporationIdentifierType}" est requis ou est invalide`} /> }
                    </FormControl>
                </>
                : <FormControl isDisabled={disabled} marginTop={3}>
                    <FormLabel fontSize="1.25em">Numéro de TVA intracommunautaire</FormLabel>
                    <G1Input component="form" registerInput={{ ...register("vatNumber") }} textTransform="uppercase" onChange={onChange} />
                    { errors.vatNumber && <ErrorField fieldName="Numéro TVA" /> }
                </FormControl>
            }

            <AddressForm prepId={prepId} required={required} disabled={disabled} displayCountry={!showUniqueId} countries={countries} errors={errors} register={register} onChange={onChange} />
        </>
    ) ;
} ;