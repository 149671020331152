import { $length, $ok } from "foundation-ts/commons";
import { Nullable, UUID } from "foundation-ts/types";
import { UserDto } from "g1-commons/lib/doxecureClientTypes";

import { useQuery } from "react-query";

import { getUserProfile } from "../services/users.service";

const useProfile = (userID:Nullable<UUID>, from?:Nullable<string>) => useQuery<UserDto>(["profile", userID], () => {
    if (!$ok(userID)) { 
        throw ($length(from) ? `${from}: `: '')+"Impossible to fetch user profile if we don't know the user identifier" ; 
    }

    return getUserProfile(userID!)
});

export default useProfile;
